<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import Stat from "@/components/widgets/stat";

export default {
  locales: {
    pt: {
      Boards: "Tabuleiros",
      Board: "Tabuleiro",
      "BACK": "VOLTAR",
      "To activate your board make your donation to the Legend.": "Para ativar o seu tabuleiro faça a sua doação para o Lenda.",
      "Visualization of the unavailable board at the moment.": "Visualização do tabuleiro indisponível no momento.",
      "GET IN TOUCH": "ENTRAR EM CONTATO",
      "Would you like to confirm the donation in the amount of": "Deseja confirmar a doação no valor de",
      "from": "de",
    },
    es: {
      Boards: "Tableros",
      Board: "Tablero",
      "BACK": "VOLVER",
      "To activate your board make your donation to the Legend.": "Para activar su tablero, haga su donación al Lenda.",
      "Visualization of the unavailable board at the moment.": "Visualización del tablero no disponible en este momento.",
      "GET IN TOUCH": "ENTRAR EN CONTACTO",
      "Would you like to confirm the donation in the amount of": "Deseja confirmar a doação no valor de",
      "from": "de",
    },
  },
  components: {
    Layout,
    Stat,
  },
  data() {
    return {
      statData: null,
      board: null,
      position: null,
      levels: null,
      tree: null,

      modal: {
        status: false,
        loading: false,
        user: null,
        sponsor: null,
      }
    };
  },
  methods: {
    getPosition() {
      this.statData = null,
      this.board = null;
      this.position = null;
      this.levels = null;
      this.tree = null;

      api.get("boards/positions/" + this.$route.params.id).then((response) => {
        if (response.data.status == "success") {
          this.board = response.data.board;
          this.position = response.data.position;
          this.getStatData();

          this.levels = response.data.levels;
          this.tree = response.data.tree;
        } else {
          this.$router.push("/boards");
        }
      });
    },
    getStatData() {
      this.statData = [
        {
          title: "Code",
          value: this.board.id.split("-")[0],
        },
        {
          title: "Boards",
          value: this.position.type.name,
        },
        {
          title: "Donations Received",
          value: this.$options.filters.currency(this.position.received),
        },
        {
          title: "Status",
          value: this.position.status,
        },
      ];
    },
    showPosition(id) {
      this.modal.user = null;
      this.modal.sponsor = null;
      this.modal.status = true;

      api.get("boards/positions/info/" + id).then((response) => {
        if (response.data.status == "success") {
          this.modal.user = response.data.user
          this.modal.sponsor = response.data.sponsor
        }
      });
    },
    confirmDonation(id) {
      this.modal.loading = true;

      api
        .post("boards/donations/confirm", {
          position: id,
        })
        .then((response) => {
          if (response.data.status == "success") {
            this.$noty.success(response.data.message)

            this.getPosition();
          } else {
            this.$noty.error(response.data.message)
          }

          this.modal.status = false;
          this.modal.user = null;
          this.modal.loading = false;
        });
    }
  },
  mounted() {
    this.getPosition();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex justify-content-between align-items-center"
        >
          <h4 class="mb-0 font-size-18">{{ t("Boards") }}</h4>
          <div class="ml-2">
            <router-link
              v-if="board"
              tag="a"
              :to="'/boards/' + board.id"
              class="btn btn-light btn-sm"
              >{{ t("BACK") }}</router-link
            >
            <button
              class="btn btn-light btn-sm ml-2"
              v-on:click="getPosition()"
              ><i class="lnr lnr-sync "></i></button
            >
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div
        v-for="stat of statData"
        :key="stat.icon"
        class="col-md-3 col-lg-3 col-xl-3"
      >
        <Stat
          :type="stat.type"
          :icon="stat.icon"
          :title="stat.title"
          :value="stat.value"
        />
      </div>
    </div>

    <div
      v-if="position && position.status && position.status == 'pending'"
      class="bg-soft-warning p-3 mb-3 rounded d-flex align-items-center"
    >
      <i class="lnr lnr-warning" style="font-size: 30px"></i>
      <div class="px-3 flex-fill">
        <h6 class="m-0 font-size-15">
          {{ t("To activate your board make your donation to the Legend.") }}
        </h6>
      </div>
    </div>

    <div v-if="position" class="divisor mb-3">
      <span>{{ t("Board") }}</span>
    </div>

    <div v-if="!position" class="text-center">
      <b-spinner small class="ml-2 align-middle" variant="dark"></b-spinner>
    </div>
    <div v-else-if="position">
      <div class="tree-legend d-md-flex justify-content-center mb-2">
        <div class="tree-column text-uppercase" v-for="(row, index) in levels" :key="index">
          <strong>{{ row }}</strong>
        </div>
      </div>
      <div class="card" v-if="tree && !tree.id">
        <div class="card-body text-center">
          {{ t("Visualization of the unavailable board at the moment.") }}
        </div>
      </div>
      <div class="card" v-else-if="tree && tree.id">
        <div class="card-body p-1 py-3 d-md-flex justify-content-center align-items-center">
          <div class="tree-column tree-left text-center">
            <div class="tree-position">
              <template v-if="tree.childrens[0] && tree.childrens[0][3] && tree.childrens[0][3][0] && tree.childrens[0][3][0].id">
                <button class="border-0 p-0 m-0" v-on:click="showPosition(tree.childrens[0][3][0].id)">
                  <img class="tree-pin" :src="require('@/assets/images/boards/' + position.type.id + '-doador.png')" />
                  <span class="tree-user notranslate" :class="tree.childrens[0][3][0].status + ' ' + tree.childrens[0][3][0].network">{{ tree.childrens[0][3][0].username }}</span>
                </button>
              </template>
              <template v-else>
                <img class="tree-pin disabled" :src="require('@/assets/images/boards/' + position.type.id + '-doador.png')" />
              </template>
            </div>

            <div class="tree-position">
              <template v-if="tree.childrens[0] && tree.childrens[0][3] && tree.childrens[0][3][1] && tree.childrens[0][3][1].id">
                <button class="border-0 p-0 m-0" v-on:click="showPosition(tree.childrens[0][3][1].id)">
                  <img class="tree-pin" :src="require('@/assets/images/boards/' + position.type.id + '-doador.png')" />
                  <span class="tree-user notranslate" :class="tree.childrens[0][3][1].status + ' ' + tree.childrens[0][3][1].network">{{ tree.childrens[0][3][1].username }}</span>
                </button>
              </template>
              <template v-else>
                <img class="tree-pin disabled" :src="require('@/assets/images/boards/' + position.type.id + '-doador.png')" />
              </template>
            </div>

            <div class="tree-position">
              <template v-if="tree.childrens[0] && tree.childrens[0][3] && tree.childrens[0][3][2] && tree.childrens[0][3][2].id">
                <button class="border-0 p-0 m-0" v-on:click="showPosition(tree.childrens[0][3][2].id)">
                  <img class="tree-pin" :src="require('@/assets/images/boards/' + position.type.id + '-doador.png')" />
                  <span class="tree-user notranslate" :class="tree.childrens[0][3][2].status + ' ' + tree.childrens[0][3][2].network">{{ tree.childrens[0][3][2].username }}</span>
                </button>
              </template>
              <template v-else>
                <img class="tree-pin disabled" :src="require('@/assets/images/boards/' + position.type.id + '-doador.png')" />
              </template>
            </div>

            <div class="tree-position">
              <template v-if="tree.childrens[0] && tree.childrens[0][3] && tree.childrens[0][3][3] && tree.childrens[0][3][3].id">
              <button class="border-0 p-0 m-0" v-on:click="showPosition(tree.childrens[0][3][3].id)">
                <img class="tree-pin" :src="require('@/assets/images/boards/' + position.type.id + '-doador.png')" />
                <span class="tree-user notranslate" :class="tree.childrens[0][3][3].status + ' ' + tree.childrens[0][3][3].network">{{ tree.childrens[0][3][3].username }}</span>
              </button>
            </template>
            <template v-else>
              <img class="tree-pin disabled" :src="require('@/assets/images/boards/' + position.type.id + '-doador.png')" />
            </template>
            </div>
          </div>
          <div class="tree-column tree-left">
            <div class="tree-position">
              <template v-if="tree.childrens[0] && tree.childrens[0][2] && tree.childrens[0][2][0] && tree.childrens[0][2][0].id">
                <button class="border-0 p-0 m-0" v-on:click="showPosition(tree.childrens[0][2][0].id)">
                  <img class="tree-pin" :src="require('@/assets/images/boards/' + position.type.id + '-construtor.png')" />
                  <span class="tree-user notranslate" :class="tree.childrens[0][2][0].status + ' ' + tree.childrens[0][2][0].network">{{ tree.childrens[0][2][0].username }}</span>
                </button>
              </template>
              <template v-else>
                <img class="tree-pin disabled" :src="require('@/assets/images/boards/' + position.type.id + '-construtor.png')" />
              </template>
            </div>

            <div class="tree-position">
              <template v-if="tree.childrens[0] && tree.childrens[0][2] && tree.childrens[0][2][1] && tree.childrens[0][2][1].id">
                <button class="border-0 p-0 m-0" v-on:click="showPosition(tree.childrens[0][2][1].id)">
                  <img class="tree-pin" :src="require('@/assets/images/boards/' + position.type.id + '-construtor.png')" />
                  <span class="tree-user notranslate" :class="tree.childrens[0][2][1].status + ' ' + tree.childrens[0][2][1].network">{{ tree.childrens[0][2][1].username }}</span>
                </button>
              </template>
              <template v-else>
                <img class="tree-pin disabled" :src="require('@/assets/images/boards/' + position.type.id + '-construtor.png')" />
              </template>
            </div>
          </div>
          <div class="tree-column tree-left">
            <div class="tree-position">
              <template v-if="tree.childrens[0] && tree.childrens[0][1] && tree.childrens[0][1][0] && tree.childrens[0][1][0].id">
                <button class="border-0 p-0 m-0" v-on:click="showPosition(tree.childrens[0][1][0].id)">
                  <img class="tree-pin" :src="require('@/assets/images/boards/' + position.type.id + '-diretor.png')" />
                  <span class="tree-user notranslate" :class="tree.childrens[0][1][0].status + ' ' + tree.childrens[0][1][0].network">{{ tree.childrens[0][1][0].username }}</span>
                </button>
              </template>
              <template v-else>
                <img class="tree-pin disabled" :src="require('@/assets/images/boards/' + position.type.id + '-diretor.png')" />
              </template>
            </div>
          </div>
          <div class="tree-column center">
            <div class="tree-position tree-receiver">
              <template v-if="tree">
                <button class="border-0 p-0 m-0" v-on:click="showPosition(tree.id)">
                  <img class="tree-pin" :src="require('@/assets/images/boards/' + position.type.id + '-lenda.png')" />
                  <span class="tree-user notranslate" :class="tree.status + ' ' + tree.network">{{ tree.username }}</span>
                </button>
              </template>
              <template v-else>
                <img class="tree-pin disabled" :src="require('@/assets/images/boards/' + position.type.id + '-lenda.png')" />
              </template>
            </div>
          </div>
          <div class="tree-column tree-right">
            <div class="tree-position">
              <template v-if="tree.childrens[1] && tree.childrens[1][1] && tree.childrens[1][1][0] && tree.childrens[1][1][0].id">
                <button class="border-0 p-0 m-0" v-on:click="showPosition(tree.childrens[1][1][0].id)">
                  <img class="tree-pin" :src="require('@/assets/images/boards/' + position.type.id + '-diretor.png')" />
                  <span class="tree-user notranslate" :class="tree.childrens[1][1][0].status + ' ' + tree.childrens[1][1][0].network">{{ tree.childrens[1][1][0].username }}</span>
                </button>
              </template>
              <template v-else>
                <img class="tree-pin disabled" :src="require('@/assets/images/boards/' + position.type.id + '-diretor.png')" />
              </template>
            </div>
          </div>
          <div class="tree-column tree-right">
            <div class="tree-position">
              <template v-if="tree.childrens[1] && tree.childrens[1][2] && tree.childrens[1][2][1] && tree.childrens[1][2][1].id">
                <button class="border-0 p-0 m-0" v-on:click="showPosition(tree.childrens[1][2][1].id)">
                  <img class="tree-pin" :src="require('@/assets/images/boards/' + position.type.id + '-construtor.png')" />
                  <span class="tree-user notranslate" :class="tree.childrens[1][2][1].status + ' ' + tree.childrens[1][2][1].network">{{ tree.childrens[1][2][1].username }}</span>
                </button>
              </template>
              <template v-else>
                <img class="tree-pin disabled" :src="require('@/assets/images/boards/' + position.type.id + '-construtor.png')" />
              </template>
            </div>

            <div class="tree-position">
              <template v-if="tree.childrens[1] && tree.childrens[1][2] && tree.childrens[1][2][0] && tree.childrens[1][2][0].id">
                <button class="border-0 p-0 m-0" v-on:click="showPosition(tree.childrens[1][2][0].id)">
                  <img class="tree-pin" :src="require('@/assets/images/boards/' + position.type.id + '-construtor.png')" />
                  <span class="tree-user notranslate" :class="tree.childrens[1][2][0].status + ' ' + tree.childrens[1][2][0].network">{{ tree.childrens[1][2][0].username }}</span>
                </button>
              </template>
              <template v-else>
                <img class="tree-pin disabled" :src="require('@/assets/images/boards/' + position.type.id + '-construtor.png')" />
              </template>
            </div>
          </div>
          <div class="tree-column tree-right">
            <div class="tree-position">
              <template v-if="tree.childrens[1] && tree.childrens[1][3] && tree.childrens[1][3][3] && tree.childrens[1][3][3].id">
                <button class="border-0 p-0 m-0" v-on:click="showPosition(tree.childrens[1][3][3].id)">
                  <img class="tree-pin" :src="require('@/assets/images/boards/' + position.type.id + '-doador.png')" />
                  <span class="tree-user notranslate" :class="tree.childrens[1][3][3].status + ' ' + tree.childrens[1][3][3].network">{{ tree.childrens[1][3][3].username }}</span>
                </button>
              </template>
              <template v-else>
                <img class="tree-pin disabled" :src="require('@/assets/images/boards/' + position.type.id + '-doador.png')" />
              </template>
            </div>

            <div class="tree-position">
              <template v-if="tree.childrens[1] && tree.childrens[1][3] && tree.childrens[1][3][2] && tree.childrens[1][3][2].id">
                <button class="border-0 p-0 m-0" v-on:click="showPosition(tree.childrens[1][3][2].id)">
                  <img class="tree-pin" :src="require('@/assets/images/boards/' + position.type.id + '-doador.png')" />
                  <span class="tree-user notranslate" :class="tree.childrens[1][3][2].status + ' ' + tree.childrens[1][3][2].network">{{ tree.childrens[1][3][2].username }}</span>
                </button>
              </template>
              <template v-else>
                <img class="tree-pin disabled" :src="require('@/assets/images/boards/' + position.type.id + '-doador.png')" />
              </template>
            </div>

            <div class="tree-position">
              <template v-if="tree.childrens[1] && tree.childrens[1][3] && tree.childrens[1][3][1] && tree.childrens[1][3][1].id">
                <button class="border-0 p-0 m-0" v-on:click="showPosition(tree.childrens[1][3][1].id)">
                  <img class="tree-pin" :src="require('@/assets/images/boards/' + position.type.id + '-doador.png')" />
                  <span class="tree-user notranslate" :class="tree.childrens[1][3][1].status + ' ' + tree.childrens[1][3][1].network">{{ tree.childrens[1][3][1].username }}</span>
                </button>
              </template>
              <template v-else>
                <img class="tree-pin disabled" :src="require('@/assets/images/boards/' + position.type.id + '-doador.png')" />
              </template>
            </div>

            <div class="tree-position">
              <template v-if="tree.childrens[1] && tree.childrens[1][3] && tree.childrens[1][3][0] && tree.childrens[1][3][0].id">
                <button class="border-0 p-0 m-0" v-on:click="showPosition(tree.childrens[1][3][0].id)">
                  <img class="tree-pin" :src="require('@/assets/images/boards/' + position.type.id + '-doador.png')" />
                  <span class="tree-user notranslate" :class="tree.childrens[1][3][0].status + ' ' + tree.childrens[1][3][0].network">{{ tree.childrens[1][3][0].username }}</span>
                </button>
              </template>
              <template v-else>
                <img class="tree-pin disabled" :src="require('@/assets/images/boards/' + position.type.id + '-doador.png')" />
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="modal.status" :title="t('Informações')" centered>
      <b-form
        v-if="modal.user"
        @submit.prevent="confirmDonation(modal.user.id)"
        class="text-center"
      >
        <div class="text-center">
          <div class="mb-3">
            <img
              v-if="modal.user.avatar"
              class="rounded-circle header-profile-user"
              :src="modal.user.avatar"
              alt=""
              style="width: 100px; height: 100px"
            />
            <img
              v-else
              class="rounded-circle header-profile-user"
              :src="
                'https://ui-avatars.com/api/?background=ED6E02&color=fff&name=' +
                modal.user.name
              "
              alt=""
              style="width: 100px; height: 100px"
            />
          </div>
          <div class="font-size-16">
            <div class="mb-3 notranslate">
              {{ modal.user.username }}<br />
              {{ modal.user.name }}<br />
              {{ modal.user.email }}<br />
              <div class="d-flex align-items-center justify-content-center">
                <div class="mr-2"><img class="img-responsive" :src="'https://flagcdn.com/24x18/' + modal.user.country.toLowerCase() + '.png'"></div>
                <div class="font-weight-bold">{{ modal.user.cellphone }}</div>
              </div>
              <a
                class="btn btn-outline-success btn-sm mt-2"
                target="_blank"
                :href="
                  'https://api.whatsapp.com/send?phone=' +
                  modal.user.cellphone.replace('+', '').replace('.', '').replace(' ', '').replace('-', '')
                "
              >
                <div class="d-flex align-items-center justify-content-center">
                  <i class="bx bxl-whatsapp font-size-24 p-0 align-middle"></i>
                  <span class="font-size-12">{{ t("GET IN TOUCH") }}</span>
                </div>
              </a>
            </div>

            <div v-if="modal.sponsor.username" class="bg-soft-light px-3 pt-2 pb-2 mb-3">
              <div class="divisor">
                <span>
                  Patrocinador
                  <i class="fa fa-eye font-size-15 align-middle"  v-b-toggle.sponsor></i>
                </span>
              </div>
              <b-collapse id="sponsor">
                <div class="notranslate my-2">
                  {{ modal.sponsor.username }}<br />
                  {{ modal.sponsor.name }}<br />
                  <div class="d-flex align-items-center justify-content-center">
                    <div class="mr-2"><img class="img-responsive" :src="'https://flagcdn.com/24x18/' + modal.sponsor.country.toLowerCase() + '.png'"></div>
                    <div class="font-weight-bold">{{ modal.sponsor.cellphone }}</div>
                  </div>
                  <a
                    class="btn btn-outline-success btn-sm mt-2"
                    target="_blank"
                    :href="
                      'https://api.whatsapp.com/send?phone=' +
                      modal.sponsor.cellphone.replace('+', '').replace('.', '').replace(' ', '').replace('-', '')
                    "
                  >
                    <div class="d-flex align-items-center justify-content-center">
                      <i class="bx bxl-whatsapp font-size-24 p-0 align-middle"></i>
                      <span class="font-size-12">{{ t("GET IN TOUCH") }}</span>
                    </div>
                  </a>
                </div>
              </b-collapse>
            </div>
          </div>
        </div>
        <div v-if="modal.user.donator == 'yes' && modal.user.status == 'pending'" class="mt-4">
          <h5 class="mb-3 pt-3 pb-3">
            {{ t("Would you like to confirm the donation in the amount of") }}
            <strong>{{ modal.user.donation | currency }}</strong>
            {{ t("from") }} <strong class="notranslate">{{ modal.user.username }}</strong
            >?
          </h5>

          <b-button
            class="btn-lg"
            :disabled="modal.loading == true"
            type="submit"
            variant="default"
          >
            Confirmar
            <b-spinner
              v-if="modal.loading"
              small
              class="ml-2 align-middle"
              variant="white"
              role="status"
            ></b-spinner>
          </b-button>
        </div>
      </b-form>
      <div v-else class="text-center">
        <b-spinner small class="ml-2 align-middle" variant="dark"></b-spinner>
      </div>
      <template v-slot:modal-footer>
        <div></div>
      </template>
    </b-modal>
  </Layout>
</template>

<style scoped>
.divisor {
  position: relative;
  text-align: center;
  text-transform: uppercase;
  z-index: 0;
  margin-bottom: 2px;
}
.divisor span {
  display: inline-block;
  padding: 5px 10px;
  background: #fff;
  font-size: 10px;
  letter-spacing: 2px;
  color: #888;
}
.divisor:before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 52%;
  height: 1px;
  background: #ccc;
}

.tree-column {
  width: calc(100% / 7);
  text-align: center;
  align-items: center;
  justify-content: center;
}
.tree-position {
  position: relative;
  height: 100%;
}
.tree-position button {
  background: none;
  padding: 0;
  width: 100%;
}
.tree-receiver {
  display: flex;
  background: url(~@/assets/images/logo-icon.png) no-repeat;
  background-size: cover;
  aspect-ratio : 1 / 1;
  align-items: center;
  justify-content: center;
}
.tree-user {
  position: absolute;
  top: 40%;
  left: 0;
  border-radius: 3px;
  margin: 0 10%;
  padding: 3px;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
  font-weight: bold;
}
.tree-user.pending {
  background: red;
  color: #fff;
}
.tree-user.active,
.tree-user.completed {
  background: green;
  color: #fff;
}
.tree-user.personal {
  color: yellow;
}
.tree-pin {
  width: 100px;
}
.tree-pin.disabled {
  filter: grayscale(1);
  opacity: 0.5;
}

@media (max-width: 767px) {
  .tree-legend {
    display: none;
  }
  .tree-column {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    flex-direction: row-reverse;
  }
  .tree-position {
    width: 25%;
  }
  .tree-user {
    width: 80%;
  }
  .tree-pin {
    width: 70px;
  }
}

@media (max-width: 400px) {
  .tree-pin {
    width: 100%;
  }
}
</style>
